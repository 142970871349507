import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import React, { useState, useEffect } from "react";
function App() {
  const [data, setData] = useState();

  const getData = async () => {
    try {
      const res = await fetch(
        "https://sheet.best/api/sheets/1998cc7a-6e44-4cb7-8e49-daf81bc012dc"
      );
      const data = await res.json();
      setData(Object.keys(data).map((key) => data[key]));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills data={data} getData={getData} />
      <Projects />
      <Contact data={data} getData={getData} />
      <Footer />
    </div>
  );
}

export default App;
