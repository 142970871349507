import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import { Table } from "react-bootstrap";
import React, { useState } from "react";
import fate from "../assets/fatenew.png";
import slime from "../assets/slimenew.png";
export const Skills = ({ data, getData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const indexOfLastData = currentPage * perPage;
  const indexOfFirstData = indexOfLastData - perPage;

  const currentData = (data ?? []).slice(
    (currentPage - 1) * perPage,
    currentPage * perPage
  );

  const imageMap = {
    Fate: fate,
    Slime: slime,
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Random Team Concept</h2>
              <p>
                In a unique twist, teams will be randomly drawn, ensuring that
                everyone has the opportunity to connect with new friends and
                strengthen existing bonds. Whether you're paired with old pals
                or new faces, the journey promises to be one filled with
                camaraderie and joy!
              </p>
              <Table
                bordered
                responsive
                hover
                className="fs--1 mt-3 text-white"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">ID Discord</th>
                    <th scope="col">Guild</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item, idx) => (
                    <tr key={idx}>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {(currentPage - 1) * perPage + idx + 1}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.NickName}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.Guild && (
                          <img
                            src={imageMap[item.Guild]}
                            alt={"a"}
                            className="ml-2"
                            style={{ width: "50px", height: "40px" }}
                          />
                        )}
                      </td>
                      {/* <td className="whitespace-nowrap px-6 py-4 font-medium">
                    <img src={no59} className="w-4 h-4" />
                    <img src={no58} className="w-4 h-4" />
                  </td> */}
                      {/* {item.SudahBayarPendaftaran === "B" ? (
                    <td className="whitespace-nowrap font-medium">
                      <span className="bg-red-700 border py-1 px-2 rounded">
                        Belum Bayar
                      </span>
                    </td>
                  ) : (
                    <td className="whitespace-nowrap px-6 py-4 font-medium">
                      <span className="bg-green-700 border py-1 px-2 rounded">
                        Sudah Bayar
                      </span>
                    </td>
                  )} */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              {Array.from(
                { length: Math.ceil((data ?? []).length / perPage) },
                (_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`btn btn-sm mx-1 ${
                      currentPage === index + 1
                        ? "btn-primary"
                        : "btn-secondary"
                    }`}
                  >
                    {index + 1}
                  </button>
                )
              )}
              <p style={{ display: "none" }}>{indexOfFirstData}</p>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="ax" />
    </section>
  );
};
