import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/Fate.jpg";
import projImg2 from "../assets/img/vogue.jpg";
import projImg3 from "../assets/img/mainbanner.png";
import projImg10 from "../assets/img/AyanaTeam.jpg";
import projImg11 from "../assets/img/SherryTeam.jpg";
import projImg12 from "../assets/img/AyanaGDN.jpg";
import projImg13 from "../assets/img/1.png";
import foto27 from "../assets/img/foto_27.png";
import rudirob from "../assets/img/RudyROB.png";
import Slimerob from "../assets/img/VogueROB.jpg";
import Slime5man from "../assets/img/Vogue5Man.jpg";
import ddnhc1 from "../assets/img/ddnhc2.jpg";
import ddnhc4 from "../assets/img/voguehc.jpg";
import ddncute from "../assets/img/cute.jpg";
import funffa from "../assets/img/vogueno1.jpg";
import ayamek from "../assets/img/ayamek.png";
import rudi from "../assets/img/RUDI.png";
import pinoy from "../assets/img/pinoy.png";
import ayanaddnhc from "../assets/img/ayanaddnhc.jpg";
import colorSharp2 from "../assets/img/color-sharp2.png";
import nono1 from "../assets/event/no_2.png";
import nono2 from "../assets/event/no_3.png";
import nono3 from "../assets/event/no_4.png";
import bogelio from "../assets/bogelioo.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Team Candu Ibadah",
      description: "June 2024",
      imgUrl: nono1,
    },
    {
      title: "Team Pusing",
      description: "June 2024",
      imgUrl: nono2,
    },
    {
      title: "Sesajen",
      description: "June 2024",
      imgUrl: nono3,
    },
    {
      title: "Fate Fun PVP",
      description: "3 Maret 2024",
      imgUrl: projImg1,
    },
    {
      title: "Fun PVP FFA",
      description: "3 Maret 2024",
      imgUrl: funffa,
    },
    // {
    //   title: "Fun PVP Dadakan",
    //   description: "11 Maret 2024",
    //   imgUrl: funpvp,
    // },
    {
      title: "Slime Fun Pvp",
      description: "30 Maret 2024",
      imgUrl: projImg2,
    },
    {
      title: "Fate x Slime",
      description: "Coming 27 April 2024",
      imgUrl: projImg3,
    },
    {
      title: "Main Event Day",
      description: "27 April 2024",
      imgUrl: foto27,
    },
    {
      title: "Fate x Slime V2",
      description: "Coming June 2024",
      imgUrl: projImg13,
    },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg1,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
  ];

  const pve = [
    {
      title: "DDN HC Fate X Slime",
      description: "6 April 2024",
      imgUrl: ddnhc1,
    },
    {
      title: "Slime DDN HC",
      description: "6 April 2024",
      imgUrl: ddnhc4,
    },
    {
      title: "Ayana DDN HC",
      description: "8 April 2024",
      imgUrl: ayanaddnhc,
    },
    {
      title: "Ayana DDN SpeedRun 30 Menit",
      description: "24 Maret 2024",
      imgUrl: projImg10,
    },
    {
      title: "Sherry DDN SpeedRun 29 Menit",
      description: "04 April 2024",
      imgUrl: projImg11,
    },
    {
      title: "Ayana First Clear GDN",
      description: "20 July 2023",
      imgUrl: projImg12,
    },
    {
      title: "Slime GDN HC 5 Man",
      description: "3 September 2023",
      imgUrl: Slime5man,
    },
  ];

  const oot = [
    {
      title: "Sweet Couple",
      description: "-",
      imgUrl: ddncute,
    },
    {
      title: "Rudi ROB CH10",
      description: "-",
      imgUrl: rudirob,
    },
    {
      title: "Slime ROB CH2",
      description: "-",
      imgUrl: Slimerob,
    },
    {
      title: "Team Ayamek",
      description: "No Smoking",
      imgUrl: ayamek,
    },
    {
      title: "Team Rudi Garut",
      description: "Fuck You!",
      imgUrl: rudi,
    },
    {
      title: "Team Pinoy",
      description: "Tangina BOBO!",
      imgUrl: pinoy,
    },
    {
      title: "Team Berantakan",
      description: "Bogelio Was Here",
      imgUrl: bogelio,
    },
    // {
    //   title: "Fate x Slime",
    //   description: "Coming 27 April 2024",
    //   imgUrl: projImg3,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg1,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Documentation</h2>
                  <p>
                    Emphasize the importance of preserving memories and
                    celebrating the journey of the gaming team together.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">PVP</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">PVE</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">OOT</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {pve.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {oot.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="section">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Cumque quam, quod neque provident velit, rem
                          explicabo excepturi id illo molestiae blanditiis,
                          eligendi dicta officiis asperiores delectus quasi
                          inventore debitis quo.
                        </p>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="a"></img>
    </section>
  );
};
