import { Col, Row } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <Row>
          <h3>
            "Get ready to laugh, cheer, and celebrate the beautiful bonds that
            make life extraordinary."
          </h3>

          {/* <Col md={6} xl={7}>
            <form>
              <div className="new-email-bx">
                <input
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                />
                <button type="submit">Submit</button>
              </div>
            </form>
          </Col> */}
        </Row>
      </div>
    </Col>
  );
};
