import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/fatexslime.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import Swal from "sweetalert2";
import fate from "../assets/fatenew.png";
import slime from "../assets/slimenew.png";

export const Contact = ({ data, getData }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [form, setForm] = useState({
    NickName: "",
    Guild: "",
  });

  const handleChangeImage = (event) => {
    setSelectedValue(event.target.value);
    setForm((prevForm) => ({
      ...prevForm,
      Guild: event.target.value,
    }));
  };

  const imageMap = {
    Fate: fate,
    Slime: slime,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const res = await fetch(
        "https://sheet.best/api/sheets/1998cc7a-6e44-4cb7-8e49-daf81bc012dc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(form),
        }
      );
      if (res.ok) {
        Swal.fire({
          icon: "success",
          title: "Berhasil Mendaftar Event",
        }).then(() => {
          getData();
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? "animate__animated animate__zoomIn" : ""
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Register Here</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          name="NickName"
                          type="text"
                          placeholder="ID Discord"
                          value={form.NickName}
                          onChange={handleChange}
                          className="form-control" // Apply consistent styling
                        />
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <div className="d-flex align-items-center">
                          <select
                            name="Guild"
                            value={selectedValue}
                            onChange={handleChangeImage}
                            className="form-control" // Apply consistent styling
                            style={{ paddingRight: "60px" }} // Adjust for image space
                          >
                            <option value="">Select Guild</option>
                            <option value="Fate">Fate</option>
                            <option value="Slime">Slime</option>
                          </select>
                          {selectedValue && (
                            <img
                              src={imageMap[selectedValue]}
                              alt={selectedValue}
                              className="ml-2"
                              style={{ width: "50px", height: "50px" }}
                            />
                          )}
                        </div>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <button className="rounded" type="submit">
                          <span>Send</span>
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
